import React, { useRef, useState } from 'react';
import { formatCurrency, formatValue } from 'utils/helpers';
import cn from 'classnames';
import pick from 'lodash/pick';
import { Small } from 'components/common/heap';
import { ReactComponent as TickIcon } from 'assets/icons/tick.svg';
import PlainNumberInput from 'components/inputs/PlainNumberInput';
import CircleButton from 'components/common/CircleButton';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as Pencil } from 'assets/icons/pencil.svg';
import s from '../CalculatorResultOverview.module.scss';
import { useTranslation } from 'react-i18next';

const ElectricityResult = ({ co2Calculations, onSaveButtonClick }) => {
  const { t } = useTranslation();
  const [showInput, setShowInput] = useState(false);
  const inputRef = useRef<any>();
  const {
    co2_emissions_before: { result },
  } = co2Calculations;

  const whiteListValues = [
    'total_power_consumption_per_year',
    'total_power_production_per_year',
    'own_consumption',
    'power_consumption_from_energy',
    'electrical_feed_into_grid',
    'ghg_savings_solar_power_system',
    'ghg_balance_of_power_consumption',
    'electricity_ghg_equivalent_car',
    'electricity_ghg_equivalent_plane',
    'electricity_ghg_equivalent_trees',
    'solar_power_system_helps_to_save',
    'total_electricity_costs',
  ];

  const electricityResultValues: any = Object.entries(pick(result, whiteListValues)).reduce((acc, [key, value]) => {
    if (key === 'total_power_consumption_per_year') {
      const tmpValue = Number.isFinite(value.user_input) ? value.user_input : value.calculated_value;
      // const parsedValue = parseFloat(tmpValue).toFixed(3) * 1; // TODO: fix me
      const parsedValue = parseFloat(tmpValue);
      return { ...acc, [key]: parsedValue };
    }
    // const parsedValue = parseFloat(tmpValue).toFixed(3) * 1; // TODO: fix me
    return { ...acc, [key]: parseFloat(value) };
  }, {});

  return (
    <div style={{ overflowX: 'scroll' }}>
      <table className={cn('table before-last-col-text-right grey-first', s.resultTable)}>
        <thead>
          <tr>
            <th colSpan={2}>
              <h4>{t('co2CalculatorResult.electricityResult.tableTitle')}</h4>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {t('co2CalculatorResult.electricityResult.totalPowerConsumptionPerYear')}
              <Small>{t('co2CalculatorResult.electricityResult.totalPowerConsumptionPerYearDescription')}</Small>
            </td>
            <td>
              {showInput ? (
                <PlainNumberInput
                  name="primary_energy_for_heating"
                  ref={inputRef}
                  defaultValue={electricityResultValues.total_power_consumption_per_year}
                />
              ) : (
                <>
                  <span>{formatValue(electricityResultValues.total_power_consumption_per_year)} kWh/a</span>
                  {` (${t(
                    Number.isFinite(result.total_power_consumption_per_year.user_input)
                      ? 'co2CalculatorResult.userValue'
                      : 'co2CalculatorResult.calculatedValue',
                  )})`}
                </>
              )}
            </td>
            <td>
              {showInput ? (
                <div className={s.buttonsWrapper}>
                  <CircleButton className={s.inputControllsWrapperCross} onClick={() => setShowInput(false)}>
                    <Close />
                  </CircleButton>
                  <CircleButton
                    className={s.inputControllsWrapperTick}
                    onClick={() =>
                      onSaveButtonClick({
                        callback: () => setShowInput(false),
                        fieldName: 'total_power_consumption_per_year',
                        fieldValue: Number(inputRef.current.state.numAsString),
                      })
                    }
                  >
                    <TickIcon />
                  </CircleButton>
                </div>
              ) : (
                <CircleButton className={s.inputControllsWrapperPencil} onClick={() => setShowInput(true)}>
                  <Pencil />
                </CircleButton>
              )}
            </td>
          </tr>
          {electricityResultValues.total_power_production_per_year ? (
            <tr>
              <td>
                {t('co2CalculatorResult.electricityResult.totalPowerProductionPerYear')}
                <Small>{t('co2CalculatorResult.electricityResult.totalPowerProductionPerYearDescription')}</Small>
              </td>
              <td>{formatValue(electricityResultValues.total_power_production_per_year)} kWh/a</td>
              <td />
            </tr>
          ) : null}
          <tr>
            <td>
              {t('co2CalculatorResult.electricityResult.powerConsumptionFromEnergy')}
              <Small>{t('co2CalculatorResult.electricityResult.powerConsumptionFromEnergyDescription')}</Small>
            </td>
            <td>{formatValue(electricityResultValues.power_consumption_from_energy)} kWh/a</td>
            <td />
          </tr>
          {electricityResultValues.electrical_feed_into_grid ? (
            <tr>
              <td>
                {t('co2CalculatorResult.electricityResult.electricalFeedInToGrid')}
                <Small>{t('co2CalculatorResult.electricityResult.electricalFeedInToGridDescription')}</Small>
              </td>
              <td>{formatValue(electricityResultValues.electrical_feed_into_grid)} kWh/a</td>
              <td />
            </tr>
          ) : null}
          {electricityResultValues.ghg_savings_solar_power_system ? (
            <tr>
              <td>
                {t('co2CalculatorResult.electricityResult.ghgSavingsSolarPowerSystem')}
                <Small>{t('co2CalculatorResult.electricityResult.ghgSavingsSolarPowerSystemDescription')}</Small>
              </td>
              <td>{formatValue(electricityResultValues.ghg_savings_solar_power_system)} kg/a</td>
              <td />
            </tr>
          ) : null}
          <tr>
            <td>
              {t('co2CalculatorResult.electricityResult.ghgBalanceOfPowerConsumption')}
              <Small>{t('co2CalculatorResult.electricityResult.ghgBalanceOfPowerConsumptionDescription')}</Small>
            </td>
            <td>{formatValue(electricityResultValues.ghg_balance_of_power_consumption)} kg/a</td>
            <td />
          </tr>
          {/* <tr>
            <td>
              {t('co2CalculatorResult.electricityResult.electricityGhgEquivalentCar')}
              <Small>{t('co2CalculatorResult.electricityResult.electricityGhgEquivalentCarDescription')}</Small>
            </td>
            <td>{formatValue(electricityResultValues.electricity_ghg_equivalent_car)} km</td>
            <td />
          </tr>
          <tr>
            <td>
              {t('co2CalculatorResult.electricityResult.electricityGhgEquivalentPlane')}
              <Small>{t('co2CalculatorResult.electricityResult.electricityGhgEquivalentPlaneDescription')}</Small>
            </td>
            <td>
              {formatValue(electricityResultValues.electricity_ghg_equivalent_plane)}
              <Small>{t('co2CalculatorResult.numberOfEconomyClassFlights')}</Small>
            </td>
            <td />
          </tr>
          <tr>
            <td>
              {t('co2CalculatorResult.electricityResult.electricityGhgEquivalentTrees')}
              <Small>{t('co2CalculatorResult.electricityResult.electricityGhgEquivalentTreesDescription')}</Small>
            </td>
            <td>
              {formatValue(electricityResultValues.electricity_ghg_equivalent_trees)}
              <Small>{t('co2CalculatorResult.numberStoredTrees')}</Small>
            </td>
            <td />
          </tr> */}
          {electricityResultValues.solar_power_system_helps_to_save ? (
            <tr>
              <td>
                {t('co2CalculatorResult.electricityResult.solarPowerSystemHelpsToSave')}
                <Small>{t('co2CalculatorResult.electricityResult.solarPowerSystemHelpsToSaveDescription')}</Small>
              </td>
              <td>{formatCurrency(electricityResultValues.solar_power_system_helps_to_save)}</td>
              <td />
            </tr>
          ) : null}
          <tr>
            <td>
              {t('co2CalculatorResult.electricityResult.totalElectricityCosts')}
              <Small>{t('co2CalculatorResult.electricityResult.totalElectricityCostsDescription')}</Small>
            </td>
            <td>{formatCurrency(electricityResultValues.total_electricity_costs)}</td>
            <td />
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ElectricityResult;
