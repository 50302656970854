import { FastField, Form, Formik, FormikHelpers } from 'formik';
import React, { useCallback } from 'react';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import Button from 'components/common/Button';
import useRequestProjectPDF from 'hooks/projectPlan/useRequestProjectPDF';
import TextInput from 'components/inputs/TextInput';
import config from 'config/config';
import { useFinalStepContext } from '../FinalStepContext';
import s from './RequestPdfForm.module.scss';
import { Col, Row } from 'react-grid-system';
import Select from 'components/inputs/Select/Select';
import dbcLogo from 'assets/images/dbc.png';

interface IRequestPdfForm {
  hideModal: () => void;
}

const validationSchema = yup.object().shape({
  firstName: yup.string().required('errors.fillField'),
  lastName: yup.string().required('errors.fillField'),
  email: yup.string().email('errors.invalidEmail').required('errors.fillField'),
});

interface IFormValues {
  email: string;
  firstName: string;
  lastName: string;
}

const initialValues: IFormValues = {
  email: '',
  firstName: '',
  lastName: '',
};

const RequestPdfForm: FC<IRequestPdfForm> = ({ hideModal }) => {
  const { t } = useTranslation();
  const { projectId } = useFinalStepContext();
  const requestPDF = useRequestProjectPDF(hideModal);

  const onSubmit = useCallback(
    async (values: IFormValues, { setSubmitting }: FormikHelpers<IFormValues>) => {
      await requestPDF({ input: values, projectId });
      setSubmitting(false);
    },
    [requestPDF, projectId],
  );

  return (
    <Formik {...{ initialValues, validationSchema, onSubmit }}>
      {({ isSubmitting }) => (
        <Form>
          <Row>
            <Col md={6}>
              <FastField name="firstName" component={TextInput} label={t('user.firstName')} />
            </Col>
            <Col md={6}>
              <FastField name="lastName" component={TextInput} label={t('user.lastName')} />
            </Col>
          </Row>
          <FastField name="email" component={TextInput} type="email" label={t('auth.email')} />

          <p className={s.confirmationText}>
            <Trans
              i18nKey="requestPdfModal.confirmationText"
              components={{
                br: <br />,
                a: (
                  <a href={config.privacyPolicyUrl} rel="noopener noreferrer" target="_blank" className="text-black" />
                ),
              }}
            />
          </p>

          <Row justify="center" align="center">
            <Col md={6}>
              <Button type="submit" disabled={isSubmitting} loading={isSubmitting} className={s.submitButton}>
                {t('requestPdfModal.submitFormBtn')}
                <Download className="ml-1" color="white" />
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default RequestPdfForm;
