import React from 'react';
import { Col, Row } from 'react-grid-system';
import dbcBg from 'assets/images/dbc-bg.png';
import s from './DBCPartnerSection.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import DownloadPdfButton from '../DownloadPdfButton';
import DBCPartnerLogo from '../DBCPartnerLogo/DBCPartnerLogo';

const DBCPartnerSection = () => {
  const { t } = useTranslation();

  return (
    <Row align="center" className={s.row} gutterWidth={60}>
      <Col md={6} xl={5}>
        <div className={s.imagesWrapper}>
          {/* <div className={s.logo}>
            <DBCPartnerLogo leftRounded />
          </div> */}
          <img src={dbcBg} className={s.bg} />
        </div>
      </Col>
      <Col md={6} xl={7}>
        <h2 className="giant-heading mt-md-0">{t('dbcPartnerSection.title')}</h2>
        <div className="paragraph">
          <Trans i18nKey="dbcPartnerSection.description" components={{ ul: <ul />, li: <li />, b: <b /> }} />
        </div>
        <div className={s.downloadPdfButton}>
          <DownloadPdfButton />
        </div>
      </Col>
    </Row>
  );
};

export default DBCPartnerSection;
