/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Locales } from 'i18n';

type AppConfig = {
  defaultLocale: Locales;
  graphqlServerUrl: string;
  isDevelopment: boolean;
  isProduction: boolean;
  privacyPolicyUrl: string;
  sentryDSN: string;
  baningoId: string;
  googleApiKey: string;
  termsOfUseUrl: string;
  fortyTwoWebsiteUrl: string;
  dbcWebsiteUrl: string;
  colorTheme: string;
};

const config: AppConfig = {
  defaultLocale: process.env.REACT_APP_DEFAULT_LOCALE ? (process.env.REACT_APP_DEFAULT_LOCALE! as Locales) : Locales.de,
  graphqlServerUrl: process.env.REACT_APP_GRAPHQL_SERVER_URL || '/graphql',
  isDevelopment: process.env.NODE_ENV !== 'production',
  isProduction: process.env.NODE_ENV === 'production',
  privacyPolicyUrl: process.env.REACT_APP_PRIVACY_POLICY_URL!,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN!,
  baningoId: process.env.REACT_APP_BANINGO_ID!,
  googleApiKey: process.env.REACT_APP_GOOGLE_CONSOLE_API_KEY,
  termsOfUseUrl: process.env.REACT_APP_TERMS_OF_USE_URL,
  fortyTwoWebsiteUrl: process.env.REACT_APP_FORTY_TWO_WATT_WEBSITE_URL,
  dbcWebsiteUrl: process.env.REACT_APP_DBC_WEBSITE_URL,
  colorTheme: process.env.REACT_APP_COLOR_THEME,
};

export default config;
