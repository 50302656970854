export default [
  {
    index: 1,
    label: 'A+',
    max: 14,
    color: '#2AD18B',
  },
  {
    index: 2,
    label: 'A',
    min: 15,
    max: 25,
    color: '#5CD66A',
  },
  {
    index: 3,
    label: 'B',
    min: 26,
    max: 50,
    color: '#96EF72',
  },
  {
    index: 4,
    label: 'C',
    min: 51,
    max: 100,
    color: '#CDEC4D',
  },
  {
    index: 5,
    label: 'D',
    min: 101,
    max: 150,
    color: '#F0E14C',
  },
  {
    index: 6,
    label: 'E',
    min: 151,
    max: 200,
    color: '#F0C143',
  },
  {
    index: 7,
    label: 'F',
    min: 201,
    max: 250,
    color: '#FA9237',
  },
  {
    index: 8,
    label: 'G',
    min: 251,
    color: '#F0513A',
  },
];
