import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import get from 'lodash/get';
import { backendErrors as enBackendErrors } from 'i18n/translations/en';
import { backendErrors as deBackendErrors } from 'i18n/translations/de';
import config from 'config/config';
import { GraphQLError } from 'graphql';

const link = ApolloLink.from([
  setContext((_, { headers }) => {
    return { headers };
  }),
  onError(({ graphQLErrors, operation }) => {
    const logError = (error: GraphQLError, errorType: string) => {
      const { message, locations, path, extensions } = error;

      const lVariables = JSON.stringify(operation.variables);
      const lPath = JSON.stringify(path);
      const lLocations = JSON.stringify(locations);
      const lMessage = JSON.stringify(message);
      console.error(
        [
          `[${errorType}]:`,
          lMessage && `Message: ${lMessage}`,
          lLocations && `Location: ${lLocations}`,
          lPath && `Path: ${lPath}`,
          lVariables && `Variables: ${lVariables}`,
        ]
          .filter(Boolean)
          .join('\n'),
        extensions,
      );
    };

    if (graphQLErrors) graphQLErrors.forEach((error: GraphQLError) => logError(error, 'GraphQL error'));
  }),
  onError(({ graphQLErrors }) => {
    const backendErrorsTranslations = { de: deBackendErrors, en: enBackendErrors };

    const translateMessage = (error: GraphQLError) => {
      const messageKey = `${config.defaultLocale}.${error.message}`;
      const translated = get(backendErrorsTranslations, messageKey, error.message);
      if (translated !== messageKey && translated) {
        error.message = translated;
      }
    };
    if (graphQLErrors) {
      graphQLErrors.forEach(translateMessage);
    }
  }),
  new HttpLink({
    uri: config.graphqlServerUrl,
    credentials: 'same-origin',
    headers: {
      SameSite: 'None',
      'Color-Theme': config.colorTheme,
    },
  }),
]);

// eslint-disable-next-line no-unused-vars
const merge = (existing = [], incoming) => incoming;

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      CO2Calculations: {
        fields: {
          co2_emissions_after: { merge },
        },
      },
    },
  }),
  assumeImmutableResults: true,
});

export default client;
