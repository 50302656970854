import Button from 'components/common/Button/Button';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import React from 'react';
import Modal, { useModal } from 'components/common/Modal/Modal';
import { useTranslation } from 'react-i18next';
import RequestPdfForm from './RequestPdfForm/RequestPdfForm';

const DownloadPdfButton = () => {
  const { t } = useTranslation();
  const { showModal, isVisible, hideModal } = useModal();

  return (
    <>
      <Button
        onClick={() => {
          showModal();
        }}
      >
        {t('requestPdfModal.requestPdfBtn')}
        <Download className="ml-1" />
      </Button>
      <Modal {...{ isVisible, close: hideModal, headerText: t('requestPdfModal.modalHeader') }}>
        <RequestPdfForm {...{ hideModal }} />
      </Modal>
    </>
  );
};

export default DownloadPdfButton;
