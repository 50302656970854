import { useParams } from 'react-router-dom';
import React, { useCallback, useMemo } from 'react';
import Loading from 'components/common/Loading';
import { camelCase, clamp, pick, round } from 'lodash';
import CustomEnergyRequirementsTable from 'components/CalculatorResultOverview/CustomEnergyRequirements/CustomEnergyRequirementsTable';
import { customEnergyRequirementWhiteList } from 'pages/ProjectDetailsCalculator.data';
import useSelectCO2Calculations from 'hooks/calculator/useSelectCO2Calculations';
import useSelectCalculationType from 'hooks/calculator/useSelectCalculationType';
import { SectionWrapper } from 'components/common/heap';
import EnergyAndCO2Balance from 'components/CalculatorResultOverview/CalculationTables/EnergyAndCO2BalanceTable';
import { formatGraphqlErrors } from 'utils/helpers';
import Alert from 'components/common/Alert';
import { ReactComponent as ArrowBack } from 'assets/icons/angle-back.svg';
import { ReactComponent as ArrowForward } from 'assets/icons/circle-arrow-forward.svg';
import Button from 'components/common/Button';
import { useTranslation } from 'react-i18next';
import { FastField, Form, Formik } from 'formik';
import * as yup from 'yup';
import RadioGroup from 'components/inputs/RadioGroup';
import { t } from 'i18next';

export const calculateTotal = (array, key) => array.reduce((s, c) => s + c[key], 0);

const validationSchema = yup.object().shape({
  type: yup.string().nullable().required('errors.chooseOne'),
});

interface IFormValues {
  type: string;
}

const initialValues: IFormValues = { type: 'custom' };

const FForm = ({ nextStep, previousStep, projectId }) => {
  const { t } = useTranslation();
  const selectCalculationType = useSelectCalculationType();

  const onSubmit = useCallback(
    async (values: IFormValues) => {
      await selectCalculationType({ projectId, type: values.type });
      nextStep();
    },
    [projectId, selectCalculationType, nextStep],
  );

  return (
    <Formik {...{ initialValues, validationSchema, onSubmit }}>
      {({ isSubmitting }) => (
        <Form>
          <FastField
            name="type"
            label="co2CalculatorForm.calculationTypeLabel"
            component={RadioGroup}
            options={[
              { label: 'co2CalculatorForm.calculationType.custom', value: 'custom' },
              { label: 'co2CalculatorForm.calculationType.system', value: 'system' },
            ]}
          />

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '30px' }}>
            <Button disabled={isSubmitting} onClick={previousStep} startIcon={<ArrowBack />} color="outline">
              {t('button.back')}
            </Button>
            <Button disabled={isSubmitting} loading={isSubmitting} type="submit" endIcon={<ArrowForward />}>
              {t('button.next')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const CalculatorSecondTab = ({ nextStep, previousStep }) => {
  const { projectId } = useParams();
  const { co2Calculations, loading, error } = useSelectCO2Calculations(projectId);

  const fuelMatrix = useMemo(
    () =>
      Object.entries(pick(co2Calculations?.custom_energy_requirements, customEnergyRequirementWhiteList))
        .filter(([k, v]) => v.user_value)
        .map(([k, { energy, costs, user_value, ghg }]) => ({
          fuelType: camelCase(k),
          energy: energy,
          costs: costs,
          userInput: user_value,
          ghg: ghg,
        })),
    [co2Calculations.custom_energy_requirements],
  );

  const customCalculations = useMemo(() => {
    const totalEnergyCosts = calculateTotal(fuelMatrix, 'costs');
    const totalEnergyBalancePerYear = calculateTotal(fuelMatrix, 'energy');
    const energyGhgBalancePerYear = calculateTotal(fuelMatrix, 'ghg');
    return {
      total_energy_costs: totalEnergyCosts,
      total_energy_balance_per_year: totalEnergyBalancePerYear,
      energy_ghg_balance_per_year: energyGhgBalancePerYear,
      energy_ghg_equivalent_car: round(energyGhgBalancePerYear / 0.15),
      energy_ghg_equivalent_plane: round(energyGhgBalancePerYear / 2200, 1),
      energy_ghg_equivalent_trees: round(energyGhgBalancePerYear / 10),
    };
  }, [fuelMatrix]);

  const deviation = useMemo(() => {
    const { calculated_value: systemValue } = co2Calculations.co2_emissions_before.result.total_energy_costs;
    const customValue = customCalculations.total_energy_costs;
    const percentage = 100 - (systemValue > customValue ? customValue / systemValue : systemValue / customValue) * 100;
    return Math.ceil(clamp(percentage, 0, 100));
  }, [co2Calculations.co2_emissions_before.result.total_energy_costs, customCalculations.total_energy_costs]);

  if (loading) return <Loading size={50} />;

  if (error) {
    return <Alert color="danger">{formatGraphqlErrors(error).message}</Alert>;
  }

  return (
    <>
      {deviation >= 25 ? (
        <Alert color="warning">{t('co2CalculatorResult.deviationWarningMessage', { value: deviation })}</Alert>
      ) : null}
      <FForm {...{ nextStep, previousStep, projectId }} />
      <CustomEnergyRequirementsTable {...{ fuelMatrix }} />
      <SectionWrapper>
        <EnergyAndCO2Balance
          {...{
            calculations: co2Calculations.co2_emissions_before.result,
            tableHeader: 'co2CalculatorResult.energyAndCo2BalanceResult.systemTableTitle',
          }}
        />
      </SectionWrapper>
      <SectionWrapper>
        <EnergyAndCO2Balance
          {...{
            calculations: customCalculations,
            tableHeader: 'co2CalculatorResult.energyAndCo2BalanceResult.customTableTitle',
          }}
        />
      </SectionWrapper>
    </>
  );
};

export default CalculatorSecondTab;
